<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/course' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('course')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="course"
                  ref="course"
                  label-width="150px"
                  class="course"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12" style="display: none">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="course.id"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="课程名" prop="courseName">
                        <el-input v-model="course.courseName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="排序" prop="seq">
                        <el-input v-model="course.seq"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                        <el-form-item label="选择分类" prop="courseTypeCode">
                          <el-select
                            v-model="course.courseTypeCode"
                            placeholder="请选择"
                            class="wp100"
                            @change="changeType"
                          >
                            <el-option
                              v-for="item in courseTypeList"
                              :key="item.courseTypeCode"
                              :label="item.courseTypeName"
                              :value="item.courseTypeCode" 
                            >
                            </el-option>
                          </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="提示码" prop="seq">
                        <el-input v-model="course.promptCode" disabled></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="提示码" prop="promptCode">
                        <el-select
                          v-model="course.promptCode"
                          placeholder="请选择"
                          class="wp100"
                          @change="changePromptCode"
                        >
                          <el-option
                            v-for="item in promptCodeList"
                            :key="item.id"
                            :label="item.promptCode"
                            :value="item.promptCode"
                           
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col> -->
                   <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="上传封面">
                         <el-upload
                      class="avatar-uploader"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img
                        v-if="course.logo"
                        :src="course.logo"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"
                        ></i>
                    </el-upload>
                      </el-form-item>
                    </el-col>
                   
                  </el-row>
                </el-form>
              </div>
              <div style="margin-left: 40px">
                <div class="f20">章节详情</div>
                <el-button
                  type="primary"
                  @click="dialogFormVisible = true"
                  style="margin-top: 20px"
                  >新增
                </el-button>
              </div>
              <el-dialog
                title="新增章节"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                @closed="closed"
              >
                <el-form :model="form" ref="form"  v-loading="isLoading"
                  element-loading-text="请稍后..."
                  element-loading-spinner="el-icon-loading">
                  <el-form-item
                    prop="chapterName"
                    label="章节名称"
                    :label-width="formLabelWidth"
                    style="margin-top: 15px"
                  >
                    <el-input
                      v-model="form.chapterName"
                      autocomplete="off"
                      style="width: 300px"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    prop="price"
                    label="章节链接"
                    :label-width="formLabelWidth"
                    style="margin-top: 15px"
                  >
                    <el-upload
                      class="upload-demo"
                      :multiple="false"
                      :action="$uploadURL"
                      :on-success="handleChange"
                      :on-progress="uploading"
                      :show-file-list="show"
                      :file-list="fileList"
                    >
                      <el-button size="small" type="text">{{
                        form.url
                      }}</el-button>
                    </el-upload>
                  </el-form-item>
                  <el-form-item
                    prop="content"
                    label="章节类型"
                    :label-width="formLabelWidth"
                    style="margin-top: 15px"
                  >
                    <!-- <el-dropdown trigger="click" @command="handleCommand"> -->
                      <!-- <span class="el-dropdown-link">
                        {{ form.type
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span> -->
                      <!-- <el-button size="small" type="text">{{
                         form.urlType=="0"? "视频": form.urlType=="4"? "PPT" : form.urlType=="2"? "Word":form.urlType=="3"? "Xlsm表格":"PDF"
                      }}</el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="0" >视频</el-dropdown-item>
                        <el-dropdown-item command="4">PPT</el-dropdown-item>
                        <el-dropdown-item command="2">Word</el-dropdown-item>
                        <el-dropdown-item command="3" >Xlsm表格</el-dropdown-item>
                        <el-dropdown-item command="1">PDF</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown> -->
                    <el-select
                            v-model="form.urlType"
                            placeholder="请选择"
                            class="wp50 capterSelect"
                          >
                            <el-option label="视频" value="0"></el-option>
                             <el-option label="PPT" value="4"></el-option>
                              <el-option label="Word" value="2"></el-option>
                               <el-option label="Xlsm表格" value="3"></el-option>
                                <el-option label="PDF" value="1"></el-option>
                          </el-select>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="addNewSpecifications"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
              <el-table :data="tableData" border style="width: 100%">
                <el-table-column
                  type="index"
                  prop="chapterNo"
                  label="编号"
                  width="180"
                  align="center"
                >
               
                </el-table-column>
                <el-table-column
                  prop="chapterName"
                  label="章节名"
                  width="180"
                  align="center"
                >
                   
                </el-table-column>
                <el-table-column prop="url" label="章节链接" align="center">
                </el-table-column>
                <!-- <el-table-column
                  prop="urlType"
                  label="章节类型"
                  width="180"
                  align="center"
                >
                </el-table-column> -->
                <el-table-column label="操作" width="180" align="center">
                  <!-- <el-button @click="del(index)" type="text" size="small"
                    >删除</el-button
                  > -->
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.id,scope.row.chapterName,scope.row.url,scope.row.urlType)"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <i
                class="el-icon-delete textblue f16 ml20"
               @click="del(scope.row.chapterName)"
              ></i>
            </el-tooltip>
          </template>
                </el-table-column>
              </el-table>
            </div>
            <!--  多功能 -->
            <!-- <CourseFunction></CourseFunction> -->

            <!-- 扩展-->
            <!-- <CourseExtends></CourseExtends> -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import CourseFunction from "@/views/course/CourseFunction.vue";
import CourseExtends from "@/views/course/CourseExtends.vue";
export default {
  components: {
    CourseFunction,
    CourseExtends,
  },
  data() {
    return {
      dialogFormVisible: false,
      //规格
      tableData: [],
      form: {
        chapterNo: "",
        chapterName: "",
        url: "点击上传",
        urlType: "选择章节类型",
      },
      show: false,
        idSon:"",
      formLabelWidth: "120px",
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      isLoading:false,
      id: "",
      tabs: ["基本信息"],
      course: {
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        promptCode: "",
        courseTypeCode: "",
        courseTypeName: "",
        courseCode: "",
        courseName: "",
        likeCount: "",
        collectCount: "",
        forwardCount: "",
        logo: "",
        seq: "",
        courseChapterList: [],
      },
      courseType: {},
      courseTypeList: [],
      courseChapterList: [],
      fileList: [],
      promptCodeList: [],
      i: 0,
    };
  },
  methods: {
    //选择分类
    changeType(value) {
      var that = this
      that.course.courseTypeCode = value;
      that.$http
        .post("/courseType/queryOne", { courseTypeCode: value })
        .then(function (response) {
          if (response.data.code == 200) {
            that.course.courseTypeName = response.data.data.courseTypeName;
            that.course.promptCode = response.data.data.promptCode;
          }
        });
    },
    //选择提示码
    changePromptCode(value) {
      this.course.promptCode = value;
    },
    //删除规格
    // del(index) {
    //   this.tableData.splice(index, 1);
    // },
    closed() {
      this.form.chapterNo = "";
      this.form.chapterName = "";
      this.form.url = "点击上传";
      this.form.urlType = "选择课程类型";
    },
    //添加章节
    addNewSpecifications() {
      if (!this.form.chapterName) {
        this.$message.warning("请输入章节名");
      } else if (this.form.url == "点击上传") {
        this.$message.warning("请上传课程链接");
      } else if (this.form.urlType == "选择课程类型") {
        this.$message.warning("请选择课程类型");
      } else {
        var list = {};
        if(this.idSon!="" && this.idSon!=undefined){
          this.dialogFormVisible = false;
           this.tableData.forEach((v)=>{
             if(v.chapterName==this.idSon){
               v.chapterName = this.form.chapterName;
        v.url = this.form.url;
        v.urlType= this.form.urlType;
            newData1.push(v)
            this.idSon=""
             }
           }) 
        }else{
           this.i = this.i + 1;
          list.chapterNo = this.i;
            list.chapterName = this.form.chapterName;
            list.url = this.form.url;
            list.urlType = this.form.urlType;
           this.tableData.push(list);
        }
       
        this.dialogFormVisible = false;
      }
    },//

    handleCommand(command) {
      this.form.urlType = command;
    },

    uploading(event, file, fileList) {
      this.show = true;
       if(event.percent==100){
          this.isLoading=true
      }
    },
    handleChange(response, file, fileList) {
      this.form.url = response.data.url;
      this.show = false;
      this.fileList = [];
      this.isLoading=false
       this.$notify.success({
            title: "提示",
            message: "上传成功",
            showClose: true,
          });
    },
    handleAvatarSuccess(res) {
      this.course.logo = res.data.url;
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type == "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
    //获取课程类型列表
    getCourseTypeList() {
      let that = this;
      that.$http
        .post("/courseType/queryList", { parentId: 0 })
        .then(function (response) {
          if (response.data.code == 200) {
            that.courseTypeList = response.data.data;
          }
        });
    },
    //获取章节列表
    getCourseChapterList() {
      let getData={};
      let that = this;
     getData.courseCode = that.course.courseCode;
      that.$http.post("/courseChapter/queryList", getData).then(function (response) {
        if (response.data.code == 200) {
          that.courseChapterList = response.data.data;
        }
      });
    },
    //获取提示码列表
    getPromptCodeList() {
      let that = this;
      that.$http
        .post("/promptCode/queryList", { parentId: 0 })
        .then(function (response) {
          if (response.data.code == 200) {
            that.promptCodeList = response.data.data;
          }
        });
    },


     //跳转到详情
    gotoDetail(id,chapterName,url,urlType) {
      this.dialogFormVisible = true;
      this.form.chapterName=chapterName;
      this.form.url=url;
      this.form.urlType=urlType;
      this.idSon=chapterName;
      this.$router.push({
        path: "/course/details",
      });
    
    },
//********* */ 
 //删除章节
 async del(chapterName) {

      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        //  this.tableData.splice(index, 1);
        // if(id!=""&&id!=undefined){
        //    this.deleteCourseChapter(id)
        // }else{
            var newData=[]
           this.tableData.forEach((v)=>{
             if(v.chapterName!=chapterName){
               newData.push(v)
             }  
           })
           this.tableData=newData;
        // }
          this.$notify.success({
            title: "提示",
            message: "删除成功",
            showClose: true,
          });
      }
    },
deleteCourseChapter(index) {
      let that = this;
      that.$http
        .post("/courseChapter/delete", { id: index })
        .then(function (response) {
          if (response.data.code == 200) {
          }
        });
         this.loadBaseInfo();
    },

    updateCourseChapter(list) {
      let that = this;
      that.$http
        .post("/courseChapter/save", list)
        .then(function (response) {
          if (response.data.code == 200) {
           this.idSon="";
          }
        });
       this.loadBaseInfo();
    },



// ***********
    goIndex() {
      this.$router.push({
        path: "/course",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
     
    },
    testChange() {
      console.log("类目选择：", this.courseType);
    },
    submitForm(formName) {
      var that = this;
      var loginName2 = localStorage.getItem("loginName");
      var abc = JSON.parse(loginName2).value;
      this.course.createBy = abc;
      that.course.courseChapterList = that.tableData;
      that.$http
        .post("/course/save", that.course)
        .then(response => {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            })
            this.goIndex()
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        // .catch(function (error) {
        //   that.$notify.info({
        //     title: "提示",
        //     message: "保存失败",
        //     showClose: true,
        //   });
        // });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/course/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.course = response.data.data;
              that.tableData = response.data.data.courseChapterList;
              console.log("加载的照片",that.course)
              this.getCourseChapterList();
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    this.getCourseTypeList();
    
    this.getPromptCodeList();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.textblue {
  color: #008eff;
}
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
        
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}

//上传封面样式
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
 .capterSelect .el-input__inner{
  color:rgb(64,158,255);
}
</style>
